import { initAccordion } from './components/initAccordion';
import { initScrollToTop } from './components/initScrollToTop';
import { initForms } from './components/initForms';
import { initStickyTableHeader } from './components/initStickyTableHeader';
import { initSliders } from './components/initSliders';
import { initTooltip } from './components/tooltips/tooltip';
import { initDefaultAnimation } from './components/initDefaultAnimation';
import { initJivosite } from './components/initJivosite';
import { initMobileFeatureMenu } from './components/initMobileFeatureMenu';
import { initRenderPricesPage } from './components/initRenderPricesPage';
import { initMailboxHacking } from './components/modal/initModal';
import { initScrollLocker } from './tools/scrollLocker';
import { initAutocompleteDevices } from './components/initAutocompleteDevices';
import { initLazyLoadFonts } from './components/fonts/initLazyLoadFonts';
import { initDropdowns } from './components/initDropdowns';
import { initCookieBanner } from './components/initCookieBanner';
import { initMobileMenu } from './components/initMobileMenu';
import { initTabs } from './components/initTabs';
import { oneDayTrialModal } from './components/modal/initOneDayTrialModal';
import { initHashEmail } from './components/initHashEmail';
import { initScrollToAnchor } from './components/initScrollToAnchor';
import { initDeclineModal } from './components/modal/initDeclineModal';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';
import { initPageLoader } from '../../../../Common/Resources/templates/components/PageLoader/initPageLoader';
import { initRippleAnimation } from '../../../../Common/Resources/templates/components/RippleAnimation/initRippleAnimation';
import { initEmailAutocompleteDropdown } from '../../../../Common/Resources/templates/components/EmailAutocompleteDropdown/initEmailAutocompleteDropdown';
import { initDetectionOrganicTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionOrganicTraffic';
import { initChunkPageBeforeCheckout } from './components/initChunkPageBeforeCheckout';
import { initDetectionReferrerTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionReferrerTraffic';
import { initGoogleAuth } from './components/initGoogleAuth';

document.addEventListener('DOMContentLoaded', function () {
    initPageLoader();
    initLazyLoadFonts(['Roboto:300,400,500,700', 'Poppins:300,400,500,600,700,800,900&display=swap']);
    initDetectionReferrerTraffic();
    initFunnelHash();
    initDetectionOrganicTraffic();
    initChunkPageBeforeCheckout(); // not main
    initScrollLocker();
    initMobileMenu();
    initAccordion(); // not main
    initScrollToTop();
    initForms(); // not main
    initStickyTableHeader(); // not main
    initSliders(); // not main
    initTooltip(); // not main
    initDropdowns(); // not main
    initDefaultAnimation(); // not main
    initMobileFeatureMenu(); // not main
    initRenderPricesPage(); // not main
    initMailboxHacking(); // not main
    initAutocompleteDevices(); // not main
    initCookieBanner(); // not main
    initTabs(); // not main
    oneDayTrialModal(); // not main
    initHashEmail();
    initScrollToAnchor(); // not main
    initDeclineModal(); // not main
    initRippleAnimation();
    initEmailAutocompleteDropdown(); // not main
    initJivosite();
    initGoogleAuth(); // not main
});

// If the browser has cached the page - hide the spinner
window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        // Hide PageLoader
        setTimeout(function () {
            if (window.pageLoader) {
                window.pageLoader.hide();
            }
        }, 1000);

        // Set current checkbox - price split
        setStyleCheckedCheckbox();
    }

    function setStyleCheckedCheckbox() {
        const radioCards = document.querySelectorAll('[data-plan-radio-card]');
        if (!radioCards.length) return;

        [...radioCards].forEach((radioCard) => {
            radioCard.checked
                ? radioCard.closest('[data-plan-radio-card-label]').classList.add('checked')
                : radioCard.closest('[data-plan-radio-card-label]').classList.remove('checked');
        });
    }
});
