import { Modal } from './Modal';

export const initMailboxHacking = () => {
    const input = document.querySelector('[data-validate-field]');
    const error = document.querySelector('[data-input-error]');
    const submit = document.querySelector('[data-submit]');
    if (!submit) return;
    const modal = new Modal();

    submit.addEventListener('click', (e) => {
        if (isValidEmail(input.value)) {
            setEmailData();
            error.classList.add('hide');
            modal.open('success-send');
            counter(70, '[data-counter]');
            setTimeout(() => {
                input.value = '';
                modal.open('success-send-review');
            }, 8500);
        } else {
            error.classList.remove('hide');
        }
    });

    function isValidEmail(value) {
        let isValid = false;
        if (value.length > 3) isValid = true;
        return isValid;
    }

    function setEmailData() {
        const inputEmail = document.querySelector('[data-validate-field]');
        const result = document.querySelector('[data-result]');
        const resultSuccess = document.querySelector('[data-result-success]');

        if (!inputEmail || !result || !resultSuccess) return;

        result.textContent = inputEmail.value;
        resultSuccess.textContent = inputEmail.value;

        if (document.querySelector('[data-mailbox]')) {
            const mailbox = document.querySelector('[data-mailbox-value]');
            result.textContent = inputEmail.value + mailbox.textContent;
            resultSuccess.textContent = inputEmail.value + mailbox.textContent;
        }
    }

    function counter(ms, className) {
        let counter = 0;
        let interval = setInterval(() => {
            document.querySelector('[data-counter]').innerHTML = ++counter + '%';
            counter === 100 ? clearInterval(interval) : false;
        }, ms);
    }

    document.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
            if (isValidEmail(input.value)) {
                setEmailData();
                error.classList.add('hide');
                modal.open('success-send');
                counter(70, '[data-counter]');
                setTimeout(() => {
                    input.value = '';
                    modal.open('success-send-review');
                }, 8500);
            } else {
                error.classList.remove('hide');
            }
        }
    });
};
